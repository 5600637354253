import React, { useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Container,
  Skeleton,
  Paper,
  Typography,
} from "@mui/material";
import OrdersDialogSmall from "../components/OrdersDialogSmall";
import OrdersDialogBig from "../components/OrdersDialogBig";
import { OrderListThunk } from "../Redux/OrdersModule/OrdersThunks";
import { useDispatch, useSelector } from "react-redux";

const OrdersBox = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(OrderListThunk());
  }, []);
  const selektor = useSelector((state) => state.OrderListReducer.orders);
  const loading = useSelector((state) => state.OrderListReducer.loading);
  return (
    <Container>
      <Paper elevation={3} sx={{ mt: "10px" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Zamówienie</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Klient</TableCell>
                <TableCell>Miejsce</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading === false
                ? selektor.map((data) => {
                    return (
                      <TableRow
                        key={data.OrderId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <OrdersDialogBig
                            OrderId={data.OrderId}
                            firstName={data.billingAddress.firstName}
                            lastName={data.billingAddress.lastName}
                          />
                        </TableCell>
                        <TableCell>
                          <OrdersDialogSmall
                            OrderId={data.OrderId}
                            status={data.OrderStatus}
                          />
                        </TableCell>
                        <TableCell>
                          {data.billingAddress.firstName}{" "}
                          {data.billingAddress.lastName}
                        </TableCell>
                        <TableCell>{data.place}</TableCell>
                      </TableRow>
                    );
                  })
                : selektor.map((data) => {
                    return (
                      <TableRow
                        key={data.OrderId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default OrdersBox;
