import types from "./OrdersTypes";

export const fetchOrderListRequest = (payload) => {
  return { type: types.FETCH_ORDER_LIST_REQUEST, payload: payload };
};

export const fetchOrderListFailure = (payload) => {
  return { type: types.FETCH_ORDER_LIST_FAILURE, payload: payload };
};
export const fetchOrderListSucces = (payload) => {
  return { type: types.FETCH_ORDER_LIST_SUCCES, payload: payload };
};

export const fetchOrderByIdRequest = (payload) => {
  return { type: types.FETCH_ORDER_BY_ID_REQUEST, payload: payload };
};

export const fetchOrderByIdFailure = (payload) => {
  return { type: types.FETCH_ORDER_BY_ID_FAILURE, payload: payload };
};
export const fetchOrderByIdSucces = (payload) => {
  return { type: types.FETCH_ORDER_BY_ID_SUCCES, payload: payload };
};

export const orderByIdClearState = () => {
  return { type: types.ORDER_BY_ID_CLEAR_STATE };
};

export const setOrderStatusInprogres = (payload) => {
  return { type: types.SET_ORDER_STATUS_INPROGRES, payload: payload };
};

export const setOrderStatusFinished = (payload) => {
  return { types: types.SET_ORDER_STATUS_FINISHED, payload: payload };
};

export const assignLocker = (payload) => {
  return { type: types.ASSIGN_LOCER, payload: payload };
};

export const assignKey = (payload) => {
  return { type: types.ASSIGN_KEY, payload: payload };
};
export const assignTrackingNumber = (payload) => {
  return { type: types.ASSIGN_TRACKING_NUMBER, payload: payload };
};
