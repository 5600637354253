import types from "./OccupanciesTypes";

const inittialState = {
  loading: false,
  error: "",
  data: {
    occupancies: [
      {
        id_occupancy: "",
        id_locker: "",
        id_season: "",
        id_order: "",
        order_comment: "",
        locker_type: "",
        locker_color: "",
        locker_position: "",
        locker_sticker: "",
        id_segment: "",
        place: "",
        floor: "",
        key_number: "",
      },
    ],
  },
};

const OccupanciesReducer = (state = inittialState, action) => {
  switch (action.type) {
    case types.FETCH_OCCUPANCIES_LIST_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_OCCUPANCIES_LIST_SUCCES:
      return { ...state, loading: false, error: "", data: action.payload };
    case types.FETCH_OCCUPANCIES_LIST_FAILURE:
      return { ...state, loading: false, error: `${action.payload}` };

    case types.CREATE_OCCUPANCY_REQUEST:
      return { ...state, loading: true };
    case types.CREATE_OCCUPANCY_SUCCES:
      return { ...state, loading: false };
    case types.CREATE_OCCUPANCY_FAILURE:
      return { ...state, loading: false, error: `${action.payload}` };

    case types.DELETE_OCCUPANCY_REQUEST:
      return { ...state, loading: true };
    case types.DELETE_OCCUPANCY_SUCCES:
      return { ...state, loading: false, data: action.payload };
    case types.DELETE_OCCUPANCY_FAILURE:
      return { ...state, loading: false, error: `${action.payload}` };

    default:
      return state;
  }
};

export default OccupanciesReducer;
