import React, { useState } from "react";
import {
  AppBar,
  Button,
  Table,
  TableRow,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  IconButton,
  Toolbar,
  Typography,
  Slide,
  Container,
  Skeleton,
} from "@mui/material";
import { Dialog } from "@mui/material";
import { ArrowDropDown, Save } from "@mui/icons-material";

import { useDispatch, useSelector } from "react-redux";
import { OrderByIdThunk } from "../Redux/OrdersModule/OrdersThunks";
import { orderByIdClearState } from "../Redux/OrdersModule/OrdersActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrdersDialogBig = ({ OrderId, firstName, lastName }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleOpen = () => {
    setOpen(true);
    dispatch(OrderByIdThunk(OrderId));
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(orderByIdClearState());
  };

  const selektor = useSelector((state) => state.OrderByIdReducer.order);
  const loading = useSelector((state) => state.OrderByIdReducer.loading);
  return (
    <Container>
      <Typography onDoubleClick={handleOpen}>
        {" "}
        Zamówienie {OrderId} {firstName} {lastName}{" "}
      </Typography>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <ArrowDropDown sx={{ fontSize: 56 }} />
              </IconButton>
              {loading === false ? (
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Zamówienie {selektor.OrderId}{" "}
                  {selektor.billingAddress.firstName}{" "}
                  {selektor.billingAddress.lastName}
                </Typography>
              ) : (
                <Skeleton variant="text" width="80%" />
              )}
              <IconButton onClick={handleClose}>
                <Save />
              </IconButton>
            </Toolbar>
          </AppBar>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell variant="head">Parametr</TableCell>
                  <TableCell>Wartość</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell variant="head">Id zamowienia</TableCell>

                  <TableCell>{selektor.OrderId}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Id klienta</TableCell>
                  <TableCell>{selektor.CustomerId}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Strona Klienta</TableCell>
                  <TableCell>{selektor.CustomerPage}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Status zamowienia</TableCell>
                  <TableCell>{selektor.OrderStatus}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Komentarz do zamowienia</TableCell>
                  <TableCell>{selektor.CustomerNote}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">adres bilingowy</TableCell>
                  <TableCell>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Imie</TableCell>
                          <TableCell>
                            {selektor.billingAddress.firstName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Nazwisko</TableCell>
                          <TableCell>
                            {selektor.billingAddress.lastName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Firma</TableCell>
                          <TableCell>
                            {selektor.billingAddress.company}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>email</TableCell>
                          <TableCell>{selektor.billingAddress.email}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>numer telefonu</TableCell>
                          <TableCell>
                            {selektor.billingAddress.phoneNumber}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Adres 1</TableCell>
                          <TableCell>
                            {selektor.billingAddress.address1}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Adres 2</TableCell>
                          <TableCell>
                            {selektor.billingAddress.address2}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Kod pocztowy</TableCell>
                          <TableCell>
                            {selektor.billingAddress.zipCode}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Miasto</TableCell>
                          <TableCell>{selektor.billingAddress.city}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>adres wysylkowy</TableCell>
                  <TableCell>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Imie</TableCell>
                          <TableCell>
                            {selektor.shippingAddress.firstName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Nazwisko</TableCell>
                          <TableCell>
                            {selektor.shippingAddress.lastName}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>numer telefonu</TableCell>
                          <TableCell>
                            {selektor.shippingAddress.phoneNumber}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Adres 1</TableCell>
                          <TableCell>
                            {selektor.shippingAddress.address1}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Adres 2</TableCell>
                          <TableCell>
                            {selektor.shippingAddress.address2}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Kod pocztowy</TableCell>
                          <TableCell>
                            {selektor.shippingAddress.zipCode}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Miasto</TableCell>
                          <TableCell>{selektor.shippingAddress.city}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>wartosc zamowienia</TableCell>
                  <TableCell>{selektor.OrderValue}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>status kaucji</TableCell>
                  <TableCell>{selektor.FeeStatus}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>wartosc kaucji</TableCell>
                  <TableCell>{selektor.FeeValue}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Data zamowienia</TableCell>
                  <TableCell>{selektor.OrderDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Data wysylki</TableCell>
                  <TableCell>{selektor.ShipmentDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Data konca wynajmu</TableCell>
                  <TableCell>{selektor.ExpireDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>SKU</TableCell>
                  <TableCell>{selektor.SKU}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Numer Szafki</TableCell>
                  <TableCell>{selektor.LockerId}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Numer kluczyka</TableCell>
                  <TableCell>{selektor.keyNumber}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Numer lisu przewozowego</TableCell>
                  <TableCell>{selektor.TrackingNumber}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      </Dialog>
    </Container>
  );
};

export default OrdersDialogBig;
