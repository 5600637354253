import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Container,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import SeasonsDialogSmall from "../components/SeasonsDialogSmall";
import PlacesDialogSmall from "../components/PlacesDialogSmall";
import LockerTypesDialogSmall from "../components/LockerTypesDialogSmall";
import LockerColorsDialogSmall from "../components/LockerColorsDialogSmall";
import SegmentProducersDialogSmall from "../components/SegmentProducersDialogSmall";

import {
  DeletePlaceThunk,
  DeleteSeasonThunk,
  DeleteLockerTypeThunk,
  DeleteLockerColorThunk,
  DeleteSegmentProducerThunk,
  DeleteLockerPositionThunk,
  DeleteFloorThunk,
} from "../Redux/SettingsModule/SettingsThunks";
import LockerPositionDialogSmall from "../components/LockerPositionDialogSmall";
import FloorDialogSmall from "../components/FloorDialogSmall";

const SettingsBox = ({}) => {
  const dispatch = useDispatch();
  const selektor = useSelector((state) => state.SettingsReducer.data);

  const deleteHandler = (id) => {
    switch (selektor.names.id) {
      case "ID Sezonu":
        return dispatch(DeleteSeasonThunk(id));
      case "ID Miejsca":
        return dispatch(DeletePlaceThunk(id));
      case "ID Typu Skrytki":
        return dispatch(DeleteLockerTypeThunk(id));
      case "ID Koloru Skrytki":
        return dispatch(DeleteLockerColorThunk(id));
      case "ID Producenta Segmentu":
        return dispatch(DeleteSegmentProducerThunk(id));
      case "ID Pozycji":
        return dispatch(DeleteLockerPositionThunk(id));
      case "ID Piętra":
        return dispatch(DeleteFloorThunk(id));
      default:
        return null;
    }
  };

  const contentBoxSwitch = () => {
    switch (selektor.names.id) {
      case "ID Sezonu":
        return <SeasonsDialogSmall />;
      case "ID Miejsca":
        return <PlacesDialogSmall />;
      case "ID Typu Skrytki":
        return <LockerTypesDialogSmall />;
      case "ID Koloru Skrytki":
        return <LockerColorsDialogSmall />;
      case "ID Producenta Segmentu":
        return <SegmentProducersDialogSmall />;
      case "ID Pozycji":
        return <LockerPositionDialogSmall />;
      case "ID Piętra":
        return <FloorDialogSmall />;

      default:
        return null;
    }
  };

  return (
    <Container>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{selektor.names.id}</TableCell>
              <TableCell>{selektor.names.cell1}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selektor.data.map((element) => {
              return (
                <TableRow key={element.id}>
                  <TableCell>{element.id}</TableCell>
                  <TableCell>{element.cell1}</TableCell>

                  <TableCell>
                    <IconButton
                      sx={{ color: "secondary.main" }}
                      onClick={() => deleteHandler(element.id)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {contentBoxSwitch()}
    </Container>
  );
};

export default SettingsBox;
