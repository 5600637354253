import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { Dialog } from "@mui/material";
import { useSelector } from "react-redux";

const OrdersDialogSmall = ({ OrderId, status }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const selektor = useSelector((state) => state.OrderListReducer.orders);
  const found = selektor.find((element) => element.OrderId === OrderId);

  return (
    <Container>
      <Typography onDoubleClick={handleOpen}> {status} </Typography>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle variant="h5">
          Zamówienie {found.OrderId} {found.billingAddress.firstName}{" "}
          {found.billingAddress.lastName}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Wprowadz dane</DialogContentText>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            // options={dummyLockers}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Skrytki" />}
          />

          <TextField
            margin="dense"
            id="KeyNumber"
            label="Numer kluczyka"
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            id="TrackingNumber"
            label="Numer listu przewozowego"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Anuluj</Button>
          <Button onClick={handleClose}>Wyślij</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default OrdersDialogSmall;
