const types = {
  ADD_TO_LIST: "ADD_TO_LIST",
  REMOVE_FROM_LIST: "REMOVE_FROM_LIST",
  EDIT_ITEM: "EDIT_ITEM",

  FETCH_LOCKERS_LIST_REQUEST: "FETCH_LOCKER_LIST_REQUEST",
  FETCH_LOCKERS_LIST_SUCCES: "FETCH_LOCKER_LIST_SUCCES",
  FETCH_LOCKERS_LIST_FAILURE: "FETCH_LOCKER_LIST_FAILURE",

  FETCH_LOCKER_BY_ID_REQUEST: "FETCH_LOCKER_BY_ID_REQUES",
  FETCH_LOCKER_BY_ID_SUCCES: "FETCH_LOCKER_BY_ID_SUCCES",
  FETCH_LOCKER_BY_ID_FAILURE: "FETCH_LOCKER_BY_ID_FAILURE",
  LOCKER_BY_ID_CLEAR_STATE: "LOCKER_BY_ID_CLEAR_STATE",

  CREATE_LOCKER_REQUEST: "CREATE_LOCKER_REQUEST",
  CREATE_LOCKER_SUCCES: "CREATE_LOCKER_SUCCES",
  CREATE_SEGMENT_SUCCES: "CREATE_SEGMENT_SUCCES",
  CREATE_LOCKER_FAILURE: "CREATE_LOCKER_FAILURE",

  DELETE_LOCKER_REQUEST: "DELETE_LOCKER_REQUEST",
  DELETE_LOCKER_SUCCES: "DELETE_LOCKER_SUCCES",
  DELETE_SEGMENT_SUCCES: "DELETE_SEGMENT_SUCCES",
  DELETE_LOCKER_FAILURE: "DELETE_LOCKER_FAILURE",

  EDIT_LOCKER_REQUEST: "EDIT_LOCKER_REQUEST",
  EDIT_LOCKER_SUCCES: "EDIT_LOCKER_SUCCES",
  EDIT_SEGMENT_SUCCES: "EDIT_SEGMENT_SUCCES",
  EDIT_LOCKER_FAILURE: "EDIT_LOCKER_FAILURE",
};

export default types;
