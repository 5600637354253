const types = {
  FETCH_OCCUPANCIES_LIST_REQUEST: "FETCH_OCCUPANCIES_LIST_REQUEST",
  FETCH_OCCUPANCIES_LIST_SUCCES: "FETCH_OCCUPANCIES_LIST_SUCCES",
  FETCH_OCCUPANCIES_LIST_FAILURE: "FETCH_OCCUPANCIES_LIST_FAILURE",

  CREATE_OCCUPANCY_REQUEST: "CREATE_OCCUPANCY_REQUEST",
  CREATE_OCCUPANCY_SUCCES: "CREATE_OCCUPANCY_SUCCES",
  CREATE_OCCUPANCY_FAILURE: "CREATE_OCCUPANCY_FAILURE",

  DELETE_OCCUPANCY_REQUEST: "DELETE_OCCUPANCY_REQUEST",
  DELETE_OCCUPANCY_SUCCES: "DELETE_OCCUPANCY_SUCCES",
  DELETE_OCCUPANCY_FAILURE: "DELETE_OCCUPANCY_FAILURE",
};

export default types;
