import React from "react";
import {
  Container,
  Typography,
  Box,
  Autocomplete,
  TextField,
  InputBase,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const Searchbar = () => {
  return (
    <Box
      sx={{
        padding: "inherit",
        backgroundColor: "rgb(0, 0, 0, 0.2)",
        borderRadius: 2,
        boxShadow:
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      }}
    >
      <SearchIcon
        sx={{
          verticalAlign: "middle",
          color: "rgb(255, 255, 255, 0.87)",
          padding: "2",
        }}
      />
      <InputBase
        id="searchBar"
        placeholder="Szukaj"
        sx={{ color: "rgb(255, 255, 255, 0.87)" }}
      ></InputBase>
    </Box>
  );
};

export default Searchbar;
