import React, { useRef, useState } from "react";
import {
  Box,
  Drawer,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Slide,
  Collapse,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import { OccupanciesListThunk } from "../Redux/OccupanciesModule/OccupanciesThunks";
import {
  SeasonsListThunk,
  PlacesListThunk,
  LockerTypesListThunk,
  LockerColorListThunk,
  SegmentProducerListThunk,
  LockerPositionsListThunk,
  FloorsListThunk,
} from "../Redux/SettingsModule/SettingsThunks";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useDispatch, useSelector } from "react-redux";
import { drawerMenuIn, setContentView } from "../Redux/UIModule/UIActions";

const DrawerNavigation = () => {
  const dispatch = useDispatch();
  const UIselektor = useSelector((state) => state.UIReducer.drawerMenuIn);

  const [settingSlider, setSettingsSlider] = useState(false);
  const containerRef = useRef(null);

  const occupanciesHandler = () => {
    dispatch(setContentView("OCCUPANCIES"));
  };

  const settingsHandler = () => {
    dispatch(drawerMenuIn(true));
    setSettingsSlider(!settingSlider);
  };

  const openHandler = () => {
    dispatch(drawerMenuIn(!UIselektor));
    setSettingsSlider(false);
  };

  const settignsTabsHandler = (thunk) => {
    dispatch(thunk);
    dispatch(setContentView("SETTINGS"));
  };
  return (
    <>
      <Drawer anchor="left" variant="permanent">
        <Box
          sx={{
            backgroundColor: "primary.main",
            height: "inherit",
            width: UIselektor ? "250px" : "88px",
            // transition: theme.transitions.create("width", {
            //   easing: theme.transitions.easing.sharp,
            //   duration: theme.transitions.duration.enteringScreen,
            // }),
          }}
        >
          <List>
            <ListItem>
              <ListItemButton onClick={() => openHandler()}>
                <ListItemIcon variant="navBarIcon">
                  {UIselektor ? <ArrowLeftIcon /> : <MenuIcon />}
                </ListItemIcon>
                {UIselektor ? (
                  <Typography variant="button">Menu</Typography>
                ) : null}
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  dispatch(setContentView("LOCKERS"));
                }}
              >
                <ListItemIcon variant="navBarIcon">
                  <WarehouseOutlinedIcon />
                </ListItemIcon>
                {UIselektor ? (
                  <Typography variant="button">Szafki</Typography>
                ) : null}
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  dispatch(setContentView("ORDERS"));
                }}
              >
                <ListItemIcon variant="navBarIcon">
                  <ShoppingCartCheckoutOutlinedIcon />
                </ListItemIcon>
                {UIselektor ? (
                  <Typography variant="button">Zamówienia</Typography>
                ) : null}
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  occupanciesHandler();
                }}
              >
                <ListItemIcon variant="navBarIcon">
                  <FactCheckOutlinedIcon />
                </ListItemIcon>
                {UIselektor ? (
                  <Typography variant="button">Obłoźenie</Typography>
                ) : null}
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  dispatch(setContentView("FEES"));
                }}
              >
                <ListItemIcon variant="navBarIcon">
                  <CurrencyExchangeOutlinedIcon />
                </ListItemIcon>
                {UIselektor ? (
                  <Typography variant="button">Kaucje</Typography>
                ) : null}
              </ListItemButton>
            </ListItem>
            <ListItem ref={containerRef}>
              <ListItemButton onClick={() => settingsHandler()}>
                <ListItemIcon variant="navBarIcon">
                  <SettingsOutlinedIcon
                    sx={{
                      transform: settingSlider
                        ? "rotate(90deg)"
                        : "rotate(270)",
                    }}
                  />
                </ListItemIcon>
                {UIselektor ? (
                  <Typography variant="button">Ustawienia</Typography>
                ) : null}
              </ListItemButton>
            </ListItem>
            <Collapse in={settingSlider} orientation="vertical">
              <List>
                <ListItem>
                  <ListItemButton
                    onClick={() => {
                      settignsTabsHandler(SeasonsListThunk());
                    }}
                  >
                    <ListItemIcon variant="navBarIcon">
                      <BuildOutlinedIcon />
                    </ListItemIcon>
                    {UIselektor ? (
                      <Typography variant="button">Sezony</Typography>
                    ) : null}
                  </ListItemButton>
                </ListItem>

                <ListItem>
                  <ListItemButton
                    onClick={() => {
                      settignsTabsHandler(PlacesListThunk());
                    }}
                  >
                    <ListItemIcon variant="navBarIcon">
                      <BuildOutlinedIcon />
                    </ListItemIcon>
                    {UIselektor ? (
                      <Typography variant="button">Miejsca</Typography>
                    ) : null}
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    onClick={() => {
                      settignsTabsHandler(FloorsListThunk());
                    }}
                  >
                    <ListItemIcon variant="navBarIcon">
                      <BuildOutlinedIcon />
                    </ListItemIcon>
                    {UIselektor ? (
                      <Typography variant="button">Piętra</Typography>
                    ) : null}
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    onClick={() => {
                      settignsTabsHandler(LockerTypesListThunk());
                    }}
                  >
                    <ListItemIcon variant="navBarIcon">
                      <BuildOutlinedIcon />
                    </ListItemIcon>
                    {UIselektor ? (
                      <Typography variant="button">Typy skrytek</Typography>
                    ) : null}
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    onClick={() => {
                      settignsTabsHandler(LockerPositionsListThunk());
                    }}
                  >
                    <ListItemIcon variant="navBarIcon">
                      <BuildOutlinedIcon />
                    </ListItemIcon>
                    {UIselektor ? (
                      <Typography variant="button">Pozycje Skrytek</Typography>
                    ) : null}
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    onClick={() => {
                      settignsTabsHandler(LockerColorListThunk());
                    }}
                  >
                    <ListItemIcon variant="navBarIcon">
                      <BuildOutlinedIcon />
                    </ListItemIcon>
                    {UIselektor ? (
                      <Typography variant="button">Kolory skrytek</Typography>
                    ) : null}
                  </ListItemButton>
                </ListItem>

                <ListItem>
                  <ListItemButton
                    onClick={() => {
                      settignsTabsHandler(SegmentProducerListThunk());
                    }}
                  >
                    <ListItemIcon variant="navBarIcon">
                      <BuildOutlinedIcon />
                    </ListItemIcon>
                    {UIselektor ? (
                      <Typography variant="button">
                        Producent Segmentów
                      </Typography>
                    ) : null}
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default DrawerNavigation;
