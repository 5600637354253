import { apiClient } from "../../services/apiClient";
import {
  fetchOrderByIdFailure,
  fetchOrderByIdRequest,
  fetchOrderByIdSucces,
  fetchOrderListFailure,
  fetchOrderListRequest,
  fetchOrderListSucces,
} from "./OrdersActions";

export const OrderListThunk = () => async (dispatch) => {
  dispatch(fetchOrderListRequest());

  try {
    const data = await apiClient().ordersInstance.get(
      "/wp-json/wc/v3/orders?per_page=20"
    );

    const parsedData = data.data.map((element) => {
      return {
        OrderId: element.id,
        OrderStatus: element.status,
        billingAddress: {
          firstName: element.billing.first_name,
          lastName: element.billing.last_name,
        },

        place: element.meta_data.find(
          (element) => element.key === "nazwa_szkoly"
        ).value,
      };
    });
    dispatch(fetchOrderListSucces(parsedData));
    // console.log(data);
  } catch (error) {
    dispatch(fetchOrderListFailure(error));
  }
};

export const OrderByIdThunk = (OrderId) => async (dispatch) => {
  dispatch(fetchOrderByIdRequest());
  try {
    const data = await apiClient().ordersInstance.get(
      `/wp-json/wc/v3/orders/${OrderId}`
    );
    console.log(data.data);
    const parsedData = {
      OrderId: data.data.id,
      CustomerId: data.data.customer_id,
      CustomerPage: data.data._links.customer.find((element) => element.href)
        .href,
      OrderStatus: data.data.status,
      CustomerNote: data.data.customer_note,
      billingAddress: {
        firstName: data.data.billing.first_name,
        lastName: data.data.billing.last_name,
        company: data.data.billing.company,
        email: data.data.billing.email,
        phoneNumber: data.data.billing.phone,
        address1: data.data.billing.address_1,
        address2: data.data.billing.address_2,
        zipCode: data.data.billing.postcode,
        city: data.data.billing.city,
      },
      shippingAddress: {
        firstName: data.data.shipping.first_name,
        lastName: data.data.shipping.last_name,
        phoneNumber: data.data.shipping.phone,
        address1: data.data.shipping.address_1,
        address2: data.data.shipping.address_2,
        zipCode: data.data.shipping.postcode,
        city: data.data.shipping.city,
      },
      OrderValue: data.data.total,

      FeeStatus: data.data.meta_data.find(
        (element) => element.key === "status_kaucji"
      ).value,

      FeeValue: data.data.fee_lines.find(
        (element) => element.name === "Kaucja zwrotna"
      ).total,
      OrderDate: data.data.date_created,
      ShipmentDate: data.data.date_completed,
      ExpireDate: "",

      SKU: data.data.meta_data.find((element) => element.key === "produkt_sku")
        .value,
      LockerId: "",
      keyNumber: "",

      TrackingNumber: data.data.fs_shipping_lines
        ? data.data.fs_shipping_lines.find((element) => element.numer_nadania)
            .numer_nadania
        : null,
    };

    dispatch(fetchOrderByIdSucces(parsedData));
  } catch (err) {
    console.log(err);
    dispatch(fetchOrderByIdFailure(err));
  }
};
