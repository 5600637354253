import { apiClient } from "../../services/apiClient";
import {
  createOccupancyThunk,
  deleteOccupancyThunk,
} from "../OccupanciesModule/OccupanciesThunks";
import {
  fetchLockersListRequest,
  fetchLockersListFailure,
  fetchLockersListSucces,
  fetchLockerByIdRequest,
  fetchLockerByIdSucces,
  fetchLockerByIdFailure,
  createLockerRequest,
  createLockerFailure,
  createLockerSucces,
  deleteLockerRequest,
  deleteLockerFailure,
  deleteLockerSucces,
  editLockerRequest,
  editLockerFailure,
  editLockerSucces,
  addToList,
  removeFromList,
  editItem,
  createSegmentSucces,
  deleteSegmentSucces,
  editSegmentSucces,
} from "./LockersActions";

export const LockersListThunk = () => async (dispatch, getState) => {
  dispatch(fetchLockersListRequest());

  try {
    const data = await apiClient().lockersInstance.get("/listlockers");
    const data1 = await apiClient().lockersInstance.get("/listsegments");
    const parsedData = {
      segments: data1.data,
      lockers: data.data,
    };

    dispatch(fetchLockersListSucces(parsedData));
  } catch (error) {
    dispatch(fetchLockersListFailure(error));
  }
};

export const LockerByIdThunk = (lockerId) => async (dispatch) => {
  dispatch(fetchLockerByIdRequest());
  try {
    const data = await apiClient().lockersInstance.get(`/findlocker`, {
      params: { id_locker: lockerId },
    });
    const parsedData = data.data;
    dispatch(fetchLockerByIdSucces(parsedData));
  } catch (err) {
    dispatch(fetchLockerByIdFailure(err));
  }
};

export const CreateLockerThunk =
  ({
    lockerId,
    id_segment,
    id_locker_color,
    id_locker_type,
    locker_sticker_number,
    id_locker_position,
  }) =>
  async (dispatch, getState) => {
    dispatch(createLockerRequest());

    try {
      const data = await apiClient().lockersInstance.post("/createlocker", {
        id_locker: lockerId,
        id_segment: id_segment,
        id_locker_color: id_locker_color,
        id_locker_type: id_locker_type,
        locker_sticker_number: locker_sticker_number,
        id_locker_position: id_locker_position,
      });
      dispatch(
        createOccupancyThunk({
          idLocker: lockerId,
          idSeason: getState().SettingsReducer.helpers.currentSeason.id_season,
        })
      );
      dispatch(
        addToList({
          id_locker: lockerId,
          id_segment: id_segment,
          id_locker_color: id_locker_color,
          id_locker_type: id_locker_type,
          locker_sticker_number: locker_sticker_number,
          id_locker_position: id_locker_position,
        })
      );
      dispatch(createLockerSucces(data.data));
    } catch (err) {
      dispatch(createLockerFailure(err));
    }
  };

export const DeleteLockerThunk =
  ({ seasonId, lockerid }) =>
  async (dispatch) => {
    dispatch(deleteLockerRequest());

    try {
      const data = await apiClient().lockersInstance.delete(
        "/deleteoccupancy",
        {
          params: { id_season: seasonId, id_locker: lockerid },
        }
      );
    } catch (err) {
    } finally {
      try {
        const response = await apiClient().lockersInstance.delete(
          "/deletelocker",
          {
            params: { id_locker: lockerid },
          }
        );
        dispatch(removeFromList(lockerid));
        dispatch(deleteLockerSucces(response));
      } catch (error) {
        dispatch(deleteLockerFailure(error));
      }
    }
  };

export const EditLockerThunk = (obj) => async (dispatch) => {
  dispatch(editLockerRequest());
  try {
    const response = await apiClient().lockersInstance.put("/editlocker", {
      id_locker: obj.id_locker,
      id_locker_type: obj.id_locker_type,
      id_locker_color: obj.id_locker_color,
      id_locker_position: obj.id_locker_position,
      locker_comment: obj.locker_comment,
      locker_sticker_number: obj.locker_sticker_number,
    });

    dispatch(editItem(response.data));
    dispatch(editLockerSucces(response.data));
  } catch (err) {
    dispatch(editLockerFailure(err));
  }
};

export const CreateSegmentThunk = (obj) => async (dispatch) => {
  dispatch(createLockerRequest());
  try {
    const response = await apiClient().lockersInstance.post("/createsegment", {
      id_segment: obj.id_segment,
      id_place: obj.id_place,
      id_floor: obj.id_floor,
      id_segment_producer: obj.id_segment_producer,
    });

    dispatch(createSegmentSucces(response.data));
  } catch (err) {
    dispatch(createLockerFailure(err));
  }
};

export const EditSegmentThunk = (obj) => async (dispatch) => {
  dispatch(editLockerRequest());
  try {
    const data = await apiClient().lockersInstance.put("/editsegment", {
      id_segment: obj.id_segment,
      id_place: obj.id_place,
      id_floor: obj.id_floor,
    });
    dispatch(editSegmentSucces(data.data));
  } catch (err) {
    dispatch(editLockerFailure(err));
  }
};

export const DeleteSegmentThunk = (id) => async (dispatch) => {
  dispatch(deleteLockerRequest());
  try {
    const response = await apiClient().lockersInstance.delete(
      "/deletesegment",
      {
        params: { id_segment: id },
      }
    );

    dispatch(deleteSegmentSucces(id));
  } catch (error) {
    dispatch(deleteLockerFailure(error));
  }
};
