import React, { useState } from "react";
import {
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Dialog } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { EditLockerThunk } from "../Redux/LockersModule/LockersThunks";

const EditLockerDialogSmall = ({ locker }) => {
  const dispatch = useDispatch();
  const settingsSelector = useSelector((state) => state.SettingsReducer);
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState(locker.locker_comment);
  const [lockerNumber, setLockerNumber] = useState(
    locker.locker_sticker_number
  );
  const [type, setType] = useState(
    settingsSelector.helpers.locker_types.find(
      (x) => x.id_locker_type == locker.id_locker_type
    )
  );

  const [color, setColor] = useState(
    settingsSelector.helpers.locker_colors.find(
      (x) => x.id_locker_color == locker.id_locker_color
    )
  );
  const [position, setPosition] = useState(
    settingsSelector.helpers.locker_positions.find(
      (x) => x.id_locker_position == locker.id_locker_position
    )
  );
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSend = () => {
    dispatch(
      EditLockerThunk({
        id_locker: locker.id_locker,
        locker_sticker_number: lockerNumber,
        locker_comment: comment,
        id_locker_type: type.id_locker_type,
        id_locker_color: color.id_locker_color,
        id_locker_position: position.id_locker_position,
      })
    );
    setOpen(false);
  };

  return (
    <Container>
      <IconButton sx={{ color: "primary.main" }} onClick={() => handleOpen()}>
        <EditIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle variant="h5">Szafka {locker.id_locker}</DialogTitle>

        <DialogContent sx={{ display: "grid", gap: 2 }}>
          <DialogContentText>Wprowadz dane</DialogContentText>

          <TextField
            margin="dense"
            label="Numer Szafki"
            fullWidth
            variant="outlined"
            value={lockerNumber}
            onChange={(val) => {
              setLockerNumber(val.target.value);
            }}
          />
          <Box>
            <FormControl sx={{ mr: 1 }}>
              <InputLabel>Typ</InputLabel>
              <Select
                label="Typ"
                value={type}
                renderValue={() => type.locker_type_name}
                onChange={(val) => setType(val.target.value)}
              >
                {settingsSelector.helpers.locker_types.map((element) => (
                  <MenuItem value={element}>
                    {element.locker_type_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ ml: 1, mr: 1 }}>
              <InputLabel>Kolor</InputLabel>
              <Select
                autoWidth
                label="Kolor"
                value={color}
                renderValue={() => color.locker_color_name}
                onChange={(val) => setColor(val.target.value)}
              >
                {settingsSelector.helpers.locker_colors.map((element) => (
                  <MenuItem value={element}>
                    {element.locker_color_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ ml: 1 }}>
              <InputLabel>Pozycja</InputLabel>
              <Select
                autoWidth
                label="Pozycja"
                value={position}
                renderValue={() => position.locker_position_name}
                onChange={(val) => setPosition(val.target.value)}
              >
                {settingsSelector.helpers.locker_positions.map((element) => (
                  <MenuItem value={element}>
                    {element.locker_position_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <TextField
            variant="outlined"
            label="Komentarz"
            fullWidth
            value={comment}
            onChange={(val) => setComment(val.target.value)}
          ></TextField>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Anuluj</Button>
          <Button onClick={() => handleSend()}>Wyślij</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default EditLockerDialogSmall;
