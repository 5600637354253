import React, { useState } from "react";
import {
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Dialog } from "@mui/material";
import { EditSegmentThunk } from "../Redux/LockersModule/LockersThunks";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";

const EditSegmentDialogSmall = ({ segment }) => {
  const settingsSelector = useSelector((state) => state.SettingsReducer);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [place, setPlace] = useState(
    settingsSelector.helpers.places.find((x) => x.id_place == segment.id_place)
  );

  const [floor, setFloor] = useState(
    settingsSelector.helpers.floors.find((x) => x.id_floor == segment.id_floor)
  );
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSend = () => {
    dispatch(
      EditSegmentThunk({
        id_segment: segment.id_segment,
        id_place: place.id_place,
        id_floor: floor.id_floor,
      })
    );
    setOpen(false);
  };

  return (
    <Container>
      <IconButton sx={{ color: "primary.main" }} onClick={() => handleOpen()}>
        <EditIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle variant="h5">Segment {segment.id_segment}</DialogTitle>
        <DialogContent>
          <DialogContentText>Wprowadz dane</DialogContentText>

          <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
            <InputLabel>Miejsce</InputLabel>
            <Select
              label="Miejsce"
              value={place}
              renderValue={() => place.place_name}
              onChange={(val) => setPlace(val.target.value)}
            >
              {settingsSelector.helpers.places.map((element) => (
                <MenuItem value={element}>{element.place_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
            <InputLabel>Piętro</InputLabel>
            <Select
              label="Piętro"
              value={floor}
              renderValue={() => floor.floor_name}
              onChange={(val) => setFloor(val.target.value)}
            >
              {settingsSelector.helpers.floors.map((element) => (
                <MenuItem value={element}>{element.floor_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Anuluj</Button>
          <Button onClick={() => handleSend()}>Wyślij</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default EditSegmentDialogSmall;
