import { createStore, applyMiddleware, compose } from "redux";
import { combineReducers } from "redux";
import { persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import AuthReducer from "./AuthModule/AuthReducer";
import OrderListReducer from "./OrdersModule/OrderListReducer";
import OrderByIdReducer from "./OrdersModule/OrderByIdReducer";
import persistReducer from "redux-persist/es/persistReducer";
import LockersListReducer from "./LockersModule/LockersListReducer";
import LockerByIdReducer from "./LockersModule/LockerByIdReducer";
import SettingsReducer from "./SettingsModule/SettingsReducer";
import UIReducer from "./UIModule/UIReducer";
import OccupanciesReducer from "./OccupanciesModule/OccupanciesReducer";

const authPersistConfig = { key: "root", storage: storage };

const reducer = combineReducers({
  auth: persistReducer(authPersistConfig, AuthReducer),
  UIReducer,
  SettingsReducer,
  LockerByIdReducer,
  LockersListReducer,
  OrderListReducer,
  OrderByIdReducer,
  OccupanciesReducer,
});
const composedEnhancers = compose(
  applyMiddleware(thunk)
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const store = createStore(reducer, composedEnhancers);
export const persistor = persistStore(store);
