import React, { useState, useRef } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Container,
  TextField,
  MenuItem,
  CircularProgress,
  Button,
  Typography,
  Collapse,
  Autocomplete,
  IconButton,
  Divider,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import EditLockerDialogSmall from "../components/EditLockerDialogSmall";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";
import EditSegmentDialogSmall from "../components/EditSegmentDialogSmall";
import {
  CreateLockerThunk,
  DeleteLockerThunk,
  LockerByIdThunk,
  EditLockerThunk,
  DeleteSegmentThunk,
  CreateSegmentThunk,
} from "../Redux/LockersModule/LockersThunks";

const CollapableRow = ({ lockers, segment }) => {
  const settingsSelector = useSelector((state) => state.SettingsReducer);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [idField, setIdField] = useState(
    `${segment.id_segment}${(lockers.length + 1).toLocaleString(undefined, {
      minimumIntegerDigits: 2,
    })}`
  );
  const [number, setNumber] = useState("");
  const [comment, setComment] = useState("");
  const [type, setType] = useState({
    id_locker_type: "",
    locker_type_name: "",
  });
  const [color, setColor] = useState({
    id_locker_color: "",
    locker_color_name: "",
  });
  const [position, setPosition] = useState({
    id_locker_position: "",
    locker_position_name: "",
  });
  const createLockerHandler = () => {
    dispatch(
      CreateLockerThunk({
        lockerId: idField,
        id_segment: segment.id_segment,
        id_locker_color: color.id_locker_color,
        id_locker_type: type.id_locker_type,
        id_locker_position: position.id_locker_position,
        locker_sticker_number: number,
      })
    );
    setIdField(parseInt(idField) + 1);
  };
  const deleteLockerHandler = (id) => {
    dispatch(
      DeleteLockerThunk({
        lockerid: id,
        seasonId: settingsSelector.helpers.currentSeason.id_season,
      })
    );
    setIdField(parseInt(idField) - 1);
  };
  return (
    <>
      <TableRow
        key={segment.id_segment}
        sx={{
          "& > *": { borderBottom: "unset", backgroundColor: "lightgray" },
        }}
      >
        <TableCell>
          <Box sx={{ display: "flex" }}>
            <IconButton
              sx={{ color: "primary.main" }}
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <EditSegmentDialogSmall segment={segment} />
          </Box>
        </TableCell>
        <TableCell>{segment.id_segment}</TableCell>
        <TableCell>
          {
            settingsSelector.helpers.places.find(
              (x) => x.id_place == segment.id_place
            ).place_name
          }
        </TableCell>
        <TableCell>
          {
            settingsSelector.helpers.floors.find(
              (x) => x.id_floor == segment.id_floor
            ).floor_name
          }
        </TableCell>
        <TableCell>
          {
            settingsSelector.helpers.segment_producers.find(
              (x) => x.id_segment_producer == segment.id_segment_producer
            ).segment_producer_name
          }
        </TableCell>
        <TableCell>
          <IconButton
            sx={{ color: "secondary.main" }}
            onClick={() => dispatch(DeleteSegmentThunk(segment.id_segment))}
          >
            <ClearIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Id Szafki</TableCell>
                    <TableCell>Numer Szafki</TableCell>
                    <TableCell>Typ</TableCell>
                    <TableCell>Kolor</TableCell>
                    <TableCell>Pozycja</TableCell>
                    <TableCell>Komentarz</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {lockers.map((element) => (
                    <TableRow key={element.id_locker}>
                      <TableCell>
                        <EditLockerDialogSmall locker={element} />
                      </TableCell>
                      <TableCell>{element.id_locker}</TableCell>
                      <TableCell>{element.locker_sticker_number}</TableCell>
                      <TableCell>
                        {
                          settingsSelector.helpers.locker_types.find(
                            (x) => x.id_locker_type == element.id_locker_type
                          ).locker_type_name
                        }
                      </TableCell>
                      <TableCell>
                        {
                          settingsSelector.helpers.locker_colors.find(
                            (x) => x.id_locker_color == element.id_locker_color
                          ).locker_color_name
                        }
                      </TableCell>
                      <TableCell>
                        {
                          settingsSelector.helpers.locker_positions.find(
                            (x) =>
                              x.id_locker_position == element.id_locker_position
                          ).locker_position_name
                        }
                      </TableCell>
                      <TableCell>{element.locker_comment}</TableCell>
                      <TableCell>
                        {" "}
                        <IconButton
                          sx={{ color: "secondary.main" }}
                          onClick={() => deleteLockerHandler(element.id_locker)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          createLockerHandler();
                        }}
                        sx={{ color: "primary.main" }}
                        disabled={
                          type.id_locker_type !== "" &&
                          color.id_locker_color !== "" &&
                          position.id_locker_position !== ""
                            ? false
                            : true
                        }
                      >
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <TextField
                        id="newLockerId"
                        variant="outlined"
                        disabled
                        value={idField}
                      ></TextField>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Numer Szafki"
                        variant="outlined"
                        value={number}
                        onChange={(val) => setNumber(val.target.value)}
                      ></TextField>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel>Typ</InputLabel>
                        <Select
                          autoWidth
                          label="Typ"
                          value={type}
                          onChange={(val) => setType(val.target.value)}
                        >
                          {settingsSelector.helpers.locker_types.map(
                            (element) => (
                              <MenuItem value={element}>
                                {element.locker_type_name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel>Kolor</InputLabel>
                        <Select
                          autoWidth
                          label="Kolor"
                          value={color}
                          onChange={(val) => setColor(val.target.value)}
                        >
                          {settingsSelector.helpers.locker_colors.map(
                            (element) => (
                              <MenuItem value={element}>
                                {element.locker_color_name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel>Pozycja</InputLabel>
                        <Select
                          autoWidth
                          label="Pozycja"
                          value={position}
                          onChange={(val) => setPosition(val.target.value)}
                        >
                          {settingsSelector.helpers.locker_positions.map(
                            (element) => (
                              <MenuItem value={element}>
                                {element.locker_position_name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        label="Komentarz"
                        value={comment}
                        onChange={(val) => setComment(val.target.value)}
                      ></TextField>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const LockersBox = () => {
  const settingsSelector = useSelector((state) => state.SettingsReducer);
  const dispatch = useDispatch();
  const selektor = useSelector((state) => state.LockersListReducer);
  const [place, setPlace] = useState("");
  const [floor, setFloor] = useState("");
  const [producer, setProducer] = useState("");
  const [idSegment, setIdSegment] = useState("");

  const createSegmentHandler = (obj) => {
    dispatch(
      CreateSegmentThunk({
        id_segment: idSegment,
        id_place: place.id_place,
        id_floor: floor.id_floor,
        id_segment_producer: producer.id_segment_producer,
      })
    );
    setIdSegment("");
  };
  return (
    <Container>
      <Paper elevation={3} sx={{ mt: "10px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>ID Segmentu</TableCell>
              <TableCell>Miejsce</TableCell>
              <TableCell>Piętro</TableCell>
              <TableCell>Producent</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {selektor.segments.map((element) => (
              <CollapableRow
                key={element.id_segment}
                lockers={selektor.lockers.filter(
                  (x) => x.id_segment == element.id_segment
                )}
                segment={element}
              />
            ))}
            <TableRow>
              <TableCell>
                <IconButton
                  disabled={
                    idSegment !== "" &&
                    place !== "" &&
                    floor !== "" &&
                    producer !== ""
                      ? false
                      : true
                  }
                  onClick={() => {
                    createSegmentHandler();
                  }}
                  sx={{ color: "primary.main" }}
                >
                  <AddIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                {" "}
                <TextField
                  variant="outlined"
                  label="ID Segmentu"
                  value={idSegment}
                  onChange={(val) => setIdSegment(val.target.value)}
                ></TextField>
              </TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <InputLabel>Miejsce</InputLabel>
                  <Select
                    autoWidth
                    label="Miejsce"
                    value={place}
                    onChange={(val) => setPlace(val.target.value)}
                  >
                    {settingsSelector.helpers.places.map((element) => (
                      <MenuItem value={element}>{element.place_name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <InputLabel>Piętro</InputLabel>
                  <Select
                    autoWidth
                    label="floor"
                    value={floor}
                    onChange={(val) => setFloor(val.target.value)}
                  >
                    {settingsSelector.helpers.floors.map((element) => (
                      <MenuItem value={element}>{element.floor_name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                {" "}
                <FormControl fullWidth>
                  <InputLabel>Producent</InputLabel>
                  <Select
                    autoWidth
                    label="segmentProducer"
                    value={producer}
                    onChange={(val) => setProducer(val.target.value)}
                  >
                    {settingsSelector.helpers.segment_producers.map(
                      (element) => (
                        <MenuItem value={element}>
                          {element.segment_producer_name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};

export default LockersBox;
