import types from "./LockersTypes";

export const addToList = (payload) => {
  return { type: types.ADD_TO_LIST, payload: payload };
};
export const removeFromList = (payload) => {
  return { type: types.REMOVE_FROM_LIST, payload: payload };
};
export const editItem = (payload) => {
  return { type: types.EDIT_ITEM, payload: payload };
};
export const fetchLockersListRequest = (payload) => {
  return { type: types.FETCH_LOCKERS_LIST_REQUEST, payload: payload };
};
export const fetchLockersListSucces = (payload) => {
  return { type: types.FETCH_LOCKERS_LIST_SUCCES, payload: payload };
};
export const fetchLockersListFailure = (payload) => {
  return { type: types.FETCH_LOCKERS_LIST_FAILURE, payload: payload };
};
export const fetchLockerByIdRequest = (payload) => {
  return { type: types.FETCH_LOCKER_BY_ID_REQUEST, payload: payload };
};
export const fetchLockerByIdSucces = (payload) => {
  return { type: types.FETCH_LOCKER_BY_ID_SUCCES, payload: payload };
};
export const fetchLockerByIdFailure = (payload) => {
  return { type: types.FETCH_LOCKER_BY_ID_FAILURE, payload: payload };
};
export const lockerByIdClearState = () => {
  return { type: types.LOCKER_BY_ID_CLEAR_STATE };
};
export const createLockerRequest = (payload) => {
  return { type: types.CREATE_LOCKER_REQUEST, payload: payload };
};
export const createLockerSucces = (payload) => {
  return { type: types.CREATE_LOCKER_SUCCES, payload: payload };
};
export const createSegmentSucces = (payload) => {
  return { type: types.CREATE_SEGMENT_SUCCES, payload: payload };
};
export const createLockerFailure = (payload) => {
  return { type: types.CREATE_LOCKER_FAILURE, payload: payload };
};
export const deleteLockerRequest = (payload) => {
  return { type: types.DELETE_LOCKER_REQUEST, payload: payload };
};
export const deleteLockerSucces = (payload) => {
  return { type: types.DELETE_LOCKER_SUCCES, payload: payload };
};

export const deleteSegmentSucces = (payload) => {
  return { type: types.DELETE_SEGMENT_SUCCES, payload: payload };
};

export const deleteLockerFailure = (payload) => {
  return { type: types.DELETE_LOCKER_FAILURE, payload: payload };
};
export const editLockerRequest = (payload) => {
  return { type: types.EDIT_LOCKER_REQUEST, payload: payload };
};
export const editLockerSucces = (payload) => {
  return { type: types.EDIT_LOCKER_SUCCES, payload: payload };
};

export const editSegmentSucces = (payload) => {
  return { type: types.EDIT_SEGMENT_SUCCES, payload: payload };
};

export const editLockerFailure = (payload) => {
  return { type: types.EDIT_LOCKER_FAILURE, payload: payload };
};
