import types from "./UITypes";

const initialState = {
  drawerMenuIn: false,
  snackbarIn: false,
  snackbarMessage: "",
  contentView: "",
};

const UIReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DRAWER_MENU_IN:
      return { ...state, drawerMenuIn: action.payload };

    case types.SET_CONTENT_VIEW:
      return { ...state, contentView: action.payload };
    case types.SNACKBAR_IN:
      return {
        ...state,
        snackbarMessage: action.payload.message,
        snackbarIn: action.payload.state,
      };
    default:
      return state;
  }
};

export default UIReducer;
