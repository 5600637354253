import types from "./SettingsTypes";

const initialState = {
  loading: false,
  error: "",
  helpers: {
    currentSeason: {},
    seasons: [],
    places: [{ id_place: "", place_name: "" }],
    floors: [{ id_floor: "", floor_name: "" }],
    locker_positions: [],
    locker_colors: [],
    locker_types: [{ id_locker_type: "", locker_type_name: "" }],
    segment_producers: [],
  },
  data: { names: { id: "", cell1: "", cell2: "", cell3: "" }, data: [] },
};

const SettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SETTINGS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_SETTINGS_SUCCES:
      return { ...state, loading: false, data: action.payload };
    case types.FETCH_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: `Failed to fetch resource : ${action.payload}`,
      };
    case types.CREATE_RESOURCE_REQUEST:
      return { ...state, loading: true };
    case types.CREATE_RESOURCE_SUCCES:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          data: [...state.data.data, action.payload.data],
        },
      };
    case types.CREATE_RESOURCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: `Failed to create resource : ${action.payload}`,
      };
    case types.DELETE_RESOURCE_REQUEST:
      return { ...state, loading: true };
    case types.DELETE_RESOURCE_SUCCES:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          data: state.data.data.filter(
            (element) => element.id !== action.payload
          ),
        },
      };
    case types.DELETE_RESOURCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: `Failed to delete resource : ${action.payload}`,
      };

    case types.FETCH_HELPERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case types.FETCH_HELPERS_SUCCES:
      return { ...state, loading: false, error: "", helpers: action.payload };
    case types.FETCH_HELPERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};

export default SettingsReducer;
