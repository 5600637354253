import React, { useRef } from "react";
import { Box, Paper, CircularProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/system";

const OccupanciesCharts = ({ data }) => {
  const theme = useTheme();

  const normalise = (value) => ((value - 0) * 100) / (data.length - 0);
  const OccValue = normalise(data.filter((x) => x.id_order != null).length);
  const VacValue = normalise(data.filter((x) => x.id_order == null).length);
  return (
    <Paper elevation={3} sx={{ mt: "10px" }}>
      <Box sx={{ height: "100px" }}>
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            float: "left",
          }}
        >
          <CircularProgress
            id="OccProgress"
            variant="determinate"
            value={OccValue}
            size={80}
            thickness={6}
            sx={{ color: theme.sortableTableColors.Occ.main }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="caption" component="div">
              Zajęte
            </Typography>
            <Typography variant="caption" component="div">
              {OccValue} %
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{ position: "relative", display: "inline-flex", float: "right" }}
        >
          <CircularProgress
            id="VacProgress"
            variant="determinate"
            value={VacValue}
            size={80}
            thickness={6}
            sx={{ color: theme.sortableTableColors.Vac.main }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="caption" component="div">
              Wolne
            </Typography>
            <Typography variant="caption" component="div">
              {VacValue} %
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default OccupanciesCharts;
