import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { snackbarIn } from "../Redux/UIModule/UIActions";

const MessageSnackbar = () => {
  const open = useSelector((state) => state.UIReducer.snackbarIn);
  const message = useSelector((state) => state.UIReducer.snackbarMessage);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(snackbarIn({ state: false }));
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3500}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={() => handleClose()}
      message={message}
    />
  );
};

export default MessageSnackbar;
