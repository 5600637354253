import types from "./OrdersTypes";

const initialState = {
  loading: false,
  error: "",
  order: {
    OrderId: "",
    CustomerId: "",
    CustomerPage: "",
    OrderStatus: "",
    CustomerNote: "",
    billingAddress: {
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      phoneNumber: "",
      address1: "",
      address2: "",
      zipCode: "",
      city: "",
    },
    shippingAddress: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      address1: "",
      address2: "",
      zipCode: "",
      city: "",
    },
    OrderValue: "",
    FeeStatus: "",
    FeeValue: "",
    OrderDate: "",
    ShipmentDate: "",
    ExpireDate: "",
    SKU: "",
    LockerId: "",
    keyNumber: "",
    TrackingNumber: "",
  },
};

const OrderByIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ORDER_BY_ID_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_ORDER_BY_ID_SUCCES:
      return {
        ...state,
        loading: false,
        order: action.payload,
        error: "",
      };
    case types.FETCH_ORDER_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: `Failed to Fetch Order ${action.payload}`,
      };
    case types.ORDER_BY_ID_CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};

export default OrderByIdReducer;
