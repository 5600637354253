import React from "react";

import { Box, AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import DrawerNavigation from "./DrawerNavigation";
import Searchbar from "./Searchbar";
import { Link, useNavigate } from "react-router-dom";
import { routeNames } from "../constants/routeNames";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Redux/AuthModule/AuthActions";

const HomeAppBar = () => {
  const UIselektor = useSelector((state) => state.UIReducer.drawerMenuIn);
  const settingsSelector = useSelector((state) => state.SettingsReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutHandler = () => {
    dispatch(logout());
    navigate(routeNames.splash);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <DrawerNavigation />
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            flexGrow: 1,
            ml: UIselektor ? "250px" : "88px",
            display: { xs: "none", sm: "block" },
          }}
        >
          LOCKER APP {settingsSelector.helpers.currentSeason.season_name}
        </Typography>
        <Searchbar />

        <IconButton
          size="large"
          edge="start"
          color="inherit"
          sx={{ mr: 2, margin: "auto" }}
          onClick={() => {
            logoutHandler();
          }}
        >
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default HomeAppBar;
