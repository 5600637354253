import axios from "axios";
import addOAuthInterceptor from "axios-oauth-1.0a";

const ordersBaseURL = "https://perfect-box.pl";
const lockersBaseUrl = "https://api.perfect-box.pl/api";

let ordersInstance = axios.create({
  baseURL: ordersBaseURL,
});

//   key: "ck_1ffa55d9282d860b3c72f9570d552bc0f1edc848",
//   secret: "cs_367c2fb74c23eb5cc56c508fffb176fa80143352",

let lockersInstance = axios.create({
  baseURL: lockersBaseUrl,
  headers: { Authorization: `Bearer 123` },

  responseType: "json",
});

export const setInstanceToken = (key, secret) => {
  console.warn(ordersInstance);

  addOAuthInterceptor(ordersInstance, {
    algorithm: "HMAC-SHA1",
    key: key,
    secret: secret,
  });
};

export const apiClient = () => {
  return { ordersInstance, lockersInstance };
};
