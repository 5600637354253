import { createTheme } from "@mui/material";

const pallete = {
  primary: {
    main: "#33A3DC",
    light: "#73d4ff",
    dark: "#0074aa",
    contrastText: "#fff",
  },
  secondary: {
    main: "#e31f26",
    light: "#ff5e50",
    dark: "#a80000",
    contrastText: "#000",
  },
};

export const theme = createTheme({
  palette: pallete,
  sortableTableColors: {
    Occ: {
      main: "rgba(255, 127, 80)",
      background: "rgba(255, 127, 80, 0.4)",
    },
    Vac: {
      main: "rgba(34, 139, 34)",
      background: "rgba(34, 139, 34, 0.4)",
    },
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
  },
  typography: {
    button: { color: pallete.primary.contrastText, fontWeight: "bold" },
    h6: { color: pallete.primary.contrastText, fontWeight: "bold" },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "sortableTableNav" },

          style: {
            color: pallete.primary.main,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            marginTop: "8px",
          },
        },
      ],
    },
    MuiListItemIcon: {
      styleOverrides: { root: { color: "primary.contrastText" } },
      variants: [
        {
          props: { variant: "navBarIcon" },

          style: {
            color: pallete.primary.contrastText,
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: { color: "rgb(255,255,255, 0.95)" },
      },
    },

    MuiGrid: {
      styleOverrides: {
        container: {
          boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        },
        root: {
          justifyContent: "center",
          display: "flex",
          justifySelf: "center",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          //Container styles
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: { margin: 0, padding: 0 },
      },
    },
  },
});
