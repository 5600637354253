import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { routeNames } from "../constants/routeNames";
import { Button, Container, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../Redux/AuthModule/AuthActions";
import { setInstanceToken } from "../services/apiClient";
import { TextField, Box, Stack, Paper } from "@mui/material";

const SplashScene = () => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [consumer, setConsumer] = useState("");
  const [secret, setSecret] = useState("");
  const navigate = useNavigate();
  const loginHandler = (consumer, secret) => {
    dispatch(login({ consumerKey: consumer, secretKey: secret }));
    navigate(routeNames.home);
  };

  return (
    <>
      {isAuthenticated === false ? (
        <Container>
          <header>
            <img src="https://perfect-box.pl/wp-content/uploads/2019/07/pb_logo_hq.jpg" />

            <Typography
              variant="h2"
              gutterBottom={true}
              sx={{ fontWeight: "bold" }}
            >
              LOCKERAPP
            </Typography>
          </header>
          <Stack spacing={2}>
            <TextField
              label="ConsumerKey"
              variant="outlined"
              placeholder="Consumer Key"
              onChange={(val) => {
                setConsumer(val.target.value);
              }}
            />
            <TextField
              label="SecretKey"
              variant="outlined"
              placeholder="Secret Key"
              onChange={(val) => {
                setSecret(val.target.value);
              }}
            />

            <Button
              variant="contained"
              onClick={() => {
                loginHandler(consumer, secret);
              }}
            >
              Authenticate
            </Button>
          </Stack>
        </Container>
      ) : (
        <Navigate to={routeNames.home} />
      )}
    </>
  );
};

export default SplashScene;
