import React, { useState } from "react";
import {
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Dialog } from "@mui/material";
import {
  CreateFloorThunk,
  CreateLockerColorThunk,
} from "../Redux/SettingsModule/SettingsThunks";
import { useDispatch } from "react-redux";

const FloorDialogSmall = ({}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [place, setPlace] = useState("");
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSend = () => {
    dispatch(CreateFloorThunk(place));
    setOpen(false);
  };

  return (
    <Container sx={{ width: "500px" }}>
      <Button
        onClick={() => {
          handleOpen();
        }}
      >
        Dodaj nowe Piętro
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle variant="h5">Nowe Piętro</DialogTitle>
        <DialogContent>
          <DialogContentText>Wprowadz dane</DialogContentText>

          <TextField
            margin="dense"
            id="FloorName"
            label="Nazwa Piętra"
            fullWidth
            variant="standard"
            onChange={(val) => {
              setPlace(val.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Anuluj</Button>
          <Button onClick={() => handleSend()}>Wyślij</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default FloorDialogSmall;
