import types from "./OrdersTypes";

const initialState = {
  loading: false,
  error: "",
  orders: [
    {
      OrderId: "",
      OrderStatus: "",
      billingAddress: {
        firstName: "",
        lastName: "",
      },
      SKU: "",
    },
  ],
};

const OrderListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ORDER_LIST_REQUEST:
      return { ...state, loading: true, error: "" };
    case types.FETCH_ORDER_LIST_SUCCES:
      return {
        ...state,
        loading: false,
        orders: action.payload,
        error: "",
      };
    case types.FETCH_ORDER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: `Failed to Fetch Orders ${action.payload}`,
      };

    default:
      return state;
  }
};

export default OrderListReducer;
