import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { Dialog } from "@mui/material";
import { CreatePlaceThunk } from "../Redux/SettingsModule/SettingsThunks";
import { useDispatch } from "react-redux";

const PlacesDialogSmall = ({}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [place, setPlace] = useState("");
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSend = () => {
    dispatch(CreatePlaceThunk(place));
    setOpen(false);
  };

  return (
    <Container sx={{ width: "500px" }}>
      <Button
        onClick={() => {
          handleOpen();
        }}
      >
        Dodaj nowe Miejsce
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle variant="h5">Nowe Miejsce</DialogTitle>
        <DialogContent>
          <DialogContentText>Wprowadz dane</DialogContentText>

          <TextField
            margin="dense"
            id="PlaceName"
            label="Nazwa Miejsca"
            fullWidth
            variant="standard"
            onChange={(val) => {
              setPlace(val.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Anuluj</Button>
          <Button onClick={() => handleSend()}>Wyślij</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PlacesDialogSmall;
