import { useSelector } from "react-redux";
import { apiClient } from "../../services/apiClient";
import {
  createOccupancyFailure,
  createOccupancyRequest,
  createOccupancySucces,
  deleteOccupancyFailure,
  deleteOccupancyRequest,
  deleteOccupancySucces,
  fetchOccupanciesListFailure,
  fetchOccupanciesListRequest,
  fetchOccupanciesListSucces,
} from "./OccupanciesActions";

export const OccupanciesListThunk = () => async (dispatch, getState) => {
  const settingsState = getState().SettingsReducer.helpers;
  const lockersState = getState().LockersListReducer;
  dispatch(fetchOccupanciesListRequest());
  try {
    const data4 = await apiClient().lockersInstance.get("/listoccupancies");

    const parseOcc = data4.data.map((element) => {
      return {
        id_season: element.id_season,
        id_occupancy: element.id_occupancy,
        id_locker: element.id_locker,
        locker_sticker_number: lockersState.lockers.find(
          (x) => x.id_locker == element.id_locker
        ).locker_sticker_number,
        key_number: element.key_number,
        id_segment: lockersState.lockers.find(
          (x) => x.id_locker == element.id_locker
        ).id_segment,
        placeId: lockersState.segments.find(
          (x) =>
            x.id_segment ==
            lockersState.lockers.find((x) => x.id_locker == element.id_locker)
              .id_segment
        ).id_place,
        place: settingsState.places.find(
          (x) =>
            x.id_place ==
            lockersState.segments.find(
              (x) =>
                x.id_segment ==
                lockersState.lockers.find(
                  (x) => x.id_locker == element.id_locker
                ).id_segment
            ).id_place
        ).place_name,
        floor: settingsState.floors.find(
          (x) =>
            x.id_floor ==
            lockersState.segments.find(
              (x) =>
                x.id_segment ==
                lockersState.lockers.find(
                  (x) => x.id_locker == element.id_locker
                ).id_segment
            ).id_floor
        ).floor_name,
        id_order: element.id_order,
        id_season: element.id_season,
        locker_type: settingsState.locker_types.find(
          (x) =>
            x.id_locker_type ==
            lockersState.lockers.find((x) => x.id_locker == element.id_locker)
              .id_locker_type
        ).locker_type_name,
        locker_color: settingsState.locker_colors.find(
          (x) =>
            x.id_locker_color ==
            lockersState.lockers.find((x) => x.id_locker == element.id_locker)
              .id_locker_color
        ).locker_color_name,
        locker_position: settingsState.locker_positions.find(
          (x) =>
            x.id_locker_position ==
            lockersState.lockers.find((x) => x.id_locker == element.id_locker)
              .id_locker_position
        ).locker_position_name,
      };
    });

    const parsedData = {
      occupancies: parseOcc,
    };

    dispatch(fetchOccupanciesListSucces(parsedData));
  } catch (err) {
    dispatch(fetchOccupanciesListFailure(err));
  }
};

export const OccupancyHistoryListThunk = (seasonId) => async (dispatch) => {
  dispatch(fetchOccupanciesListRequest());
  try {
    const data = await apiClient().lockersInstance.get(
      "listoccupancyhistorybyseason",
      {
        params: { id_season: seasonId },
      }
    );

    const parseOcc = data.data;
    const parsedData = {
      occupancies: parseOcc,
    };

    dispatch(fetchOccupanciesListSucces(parsedData));
  } catch (err) {
    dispatch(fetchOccupanciesListFailure(err));
  }
};

export const deleteOccupancyThunk =
  ({ seasonId, lockerid }) =>
  async (dispatch) => {
    dispatch(deleteOccupancyRequest());
    try {
      const data = await apiClient().lockersInstance.delete(
        "/deleteoccupancy",
        {
          params: { id_season: seasonId, id_locker: lockerid },
        }
      );
      dispatch(deleteOccupancySucces(data));
    } catch (err) {
      dispatch(deleteOccupancyFailure(err));
    }
  };

export const createOccupancyThunk =
  ({ idLocker, idSeason, idOrder, keyNumber }) =>
  async (dispatch) => {
    dispatch(createOccupancyRequest());
    try {
      const data = await apiClient().lockersInstance.post("/createoccupancy", {
        id_locker: idLocker,
        id_season: idSeason,
        id_order: idOrder,
        key_number: keyNumber,
      });
      dispatch(createOccupancySucces(data));
    } catch (err) {
      dispatch(createOccupancyFailure(err));
    }
  };
