const types = {
  FETCH_ORDER_LIST_REQUEST: "FETCH_ORDER_LIST_REQUEST",
  FETCH_ORDER_LIST_SUCCES: "FETCH_ORDER_LIST_SUCCES",
  FETCH_ORDER_LIST_FAILURE: "FETCH_ORDER_LIST_FAILURE",

  FETCH_ORDER_BY_ID_REQUEST: "FETCH_ORDER_BY_ID_REQUES",
  FETCH_ORDER_BY_ID_SUCCES: "FETCH_ORDER_BY_ID_SUCCES",
  FETCH_ORDER_BY_ID_FAILURE: "FETCH_ORDER_BY_ID_FAILURE",
  ORDER_BY_ID_CLEAR_STATE: "ORDER_BY_ID_CLEAR_STATE",

  ASSIGN_LOCER: "ASSIGN_LOCKER",
  ASSIGN_KEY: "ASSIGN_KEY",
  ASSIGN_TRACKING_NUMBER: "ASSIGN_TRACKING_NUMBER",
  SET_ORDER_STATUS_FINISHED: "SET_ORDER_STATUS_FINISHED",
  SET_ORDER_STATUS_INPROGRES: "SET_ORDER_STATUS_INPROGRES",
};

export default types;
