import types from "./LockersTypes";

const initialState = {
  loading: false,
  error: "",
  locker: {
    LockerId: "",
    SegmentId: "",
    LockerColor: "",
    LockerType: "",
    LockerComment: "",
    LockerStickerNumber: "",
  },
};

const LockerByIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_LOCKER_BY_ID_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_LOCKER_BY_ID_SUCCES:
      return { ...state, loading: false, error: "", locker: action.payload };
    case types.FETCH_LOCKER_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: `Failed to fetch Locker ${action.payload}`,
      };
    case types.LOCKER_BY_ID_CLEAR_STATE:
      return initialState;
    case types.CREATE_LOCKER_REQUEST:
      return { ...state, loading: true, error: "" };
    case types.CREATE_LOCKER_SUCCES:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case types.CREATE_LOCKER_FAILURE:
      return {
        ...state,
        loading: false,
        error: `Failed to create locker ${action.payload}`,
      };
    case types.DELETE_LOCKER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case types.DELETE_LOCKER_SUCCES:
      return { ...state, loading: false, error: "" };
    case types.DELETE_LOCKER_FAILURE:
      return {
        ...state,
        loading: false,
        error: `failed to delete locker ${action.payload}`,
      };
    case types.EDIT_LOCKER_REQUEST:
      return { ...state, loading: true, error: "" };
    case types.EDIT_LOCKER_SUCCES:
      return { ...state, loading: false, error: "", locker: action.payload };
    case types.EDIT_LOCKER_FAILURE:
      return {
        ...state,
        loading: false,
        error: `failed to edit locker ${action.payload}`,
      };

    default:
      return state;
  }
};

export default LockerByIdReducer;
