import { apiClient } from "../../services/apiClient";
import { snackbarIn } from "../UIModule/UIActions";
import {
  fetchSettingsListRequest,
  fetchSettingsListSucces,
  fetchSettingsListFailure,
  createResourceRequest,
  createResouceSucces,
  createResourceFailure,
  deleteResourceRequest,
  deleteResourceSucces,
  deleteResourceFailure,
  fetchHelpersRequest,
  fetchHelpersSucces,
  fetchHelpersFailure,
} from "./SettingsActions";

export const SeasonsListThunk = () => async (dispatch) => {
  dispatch(fetchSettingsListRequest());
  try {
    const data = await apiClient().lockersInstance.get("/listseasons");
    const parsedData = {
      names: { id: "ID Sezonu", cell1: "Nazwa Sezonu" },
      data: data.data.map((element) => {
        return { id: element.id_season, cell1: element.season_name };
      }),
    };
    dispatch(fetchSettingsListSucces(parsedData));
  } catch (err) {
    dispatch(fetchSettingsListFailure(err));
  }
};
export const CreateSeasonThunk = (name) => async (dispatch) => {
  dispatch(createResourceRequest());
  try {
    const data = await apiClient().lockersInstance.post("/createseason", {
      season_name: name,
    });
    const parsedData = {
      names: { id: "ID Sezonu", cell1: "Nazwa Sezonu" },
      data: { id: data.data.id_season, cell1: data.data.season_name },
    };

    dispatch(createResouceSucces(parsedData));
    dispatch(
      snackbarIn({
        message: `Sezon ${parsedData.data.id} ${name} utworzony`,
        state: true,
      })
    );
  } catch (err) {
    dispatch(createResourceFailure(err));
    dispatch(
      snackbarIn({
        message: `Sezon ${name} nie został utworzony`,
        state: true,
      })
    );
  }
};
export const DeleteSeasonThunk = (Id) => async (dispatch) => {
  dispatch(deleteResourceRequest());
  try {
    const data = await apiClient().lockersInstance.delete("/deleteseason", {
      params: { id_season: Id },
    });
    dispatch(deleteResourceSucces(Id));
    dispatch(snackbarIn({ message: `Sezon ${Id} usunięty`, state: true }));
  } catch (err) {
    dispatch(deleteResourceFailure(err));
    dispatch(
      snackbarIn({
        message: `Sezon ${Id} nie został usunięty`,
        state: true,
      })
    );
  }
};
export const PlacesListThunk = () => async (dispatch) => {
  dispatch(fetchSettingsListRequest());
  try {
    const data = await apiClient().lockersInstance.get("/listplaces");
    const parsedData = {
      names: { id: "ID Miejsca", cell1: "Nazwa Miejsca" },
      data: data.data.map((element) => {
        return { id: element.id_place, cell1: element.place_name };
      }),
    };
    dispatch(fetchSettingsListSucces(parsedData));
  } catch (err) {
    dispatch(fetchSettingsListFailure(err));
  }
};
export const CreatePlaceThunk = (name) => async (dispatch) => {
  dispatch(createResourceRequest());
  try {
    const data = await apiClient().lockersInstance.post("/createplace", {
      place_name: name,
    });
    const parsedData = {
      names: { id: "ID Miejsca", cell1: "Nazwa Miejsca" },
      data: { id: data.data.id_place, cell1: data.data.place_name },
    };

    dispatch(createResouceSucces(parsedData));
    dispatch(
      snackbarIn({
        message: `Miejsce ${parsedData.data.id} ${name} utworzone`,
        state: true,
      })
    );
  } catch (err) {
    dispatch(createResourceFailure(err));
    dispatch(
      snackbarIn({
        message: `Miejsce ${name} nie zostało utworzone`,
        state: true,
      })
    );
  }
};
export const DeletePlaceThunk = (Id) => async (dispatch) => {
  dispatch(deleteResourceRequest());
  try {
    const data = await apiClient().lockersInstance.delete("/deleteplace", {
      params: { id_place: Id },
    });
    dispatch(deleteResourceSucces(Id));
    dispatch(snackbarIn({ message: `Miejsce ${Id} usunięte`, state: true }));
  } catch (err) {
    dispatch(deleteResourceFailure(err));
    dispatch(
      snackbarIn({
        message: `Miejsce ${Id} nie zostało usunięte`,
        state: true,
      })
    );
  }
};
export const LockerTypesListThunk = () => async (dispatch) => {
  dispatch(fetchSettingsListRequest());
  try {
    const data = await apiClient().lockersInstance.get("/listlockertypes");
    const parsedData = {
      names: { id: "ID Typu Skrytki", cell1: "Nazwa Typu Skrytki" },
      data: data.data.map((element) => {
        return { id: element.id_locker_type, cell1: element.locker_type_name };
      }),
    };
    dispatch(fetchSettingsListSucces(parsedData));
  } catch (err) {
    dispatch(fetchSettingsListFailure(err));
  }
};
export const CreateLockerTypeThunk = (name) => async (dispatch) => {
  dispatch(createResourceRequest());
  try {
    const data = await apiClient().lockersInstance.post("/createlockertype", {
      locker_type_name: name,
    });
    const parsedData = {
      names: { id: "ID Typu Skrytki", cell1: "Nazwa Typu Skrytki" },
      data: { id: data.data.id_locker_type, cell1: data.data.locker_type_name },
    };

    dispatch(createResouceSucces(parsedData));
    dispatch(
      snackbarIn({
        message: `Typ ${name} utworzony`,
        state: true,
      })
    );
  } catch (err) {
    dispatch(createResourceFailure(err));
    dispatch(
      snackbarIn({
        message: `Typ ${name} nie został utworzony`,
        state: true,
      })
    );
  }
};
export const DeleteLockerTypeThunk = (Id) => async (dispatch) => {
  dispatch(deleteResourceRequest());
  try {
    const data = await apiClient().lockersInstance.delete("/deletelockertype", {
      params: { id_locker_type: Id },
    });
    dispatch(deleteResourceSucces(Id));
    dispatch(
      snackbarIn({ message: `Typ skrytki ${Id} usunięty`, state: true })
    );
  } catch (err) {
    dispatch(deleteResourceFailure(err));
    dispatch(
      snackbarIn({
        message: `Typ skrytki ${Id} nie został usunięty`,
        state: true,
      })
    );
  }
};
export const LockerColorListThunk = () => async (dispatch) => {
  dispatch(fetchSettingsListRequest());
  try {
    const data = await apiClient().lockersInstance.get("/listlockercolors");
    const parsedData = {
      names: { id: "ID Koloru Skrytki", cell1: "Nazwa Koloru Skrytki" },
      data: data.data.map((element) => {
        return {
          id: element.id_locker_color,
          cell1: element.locker_color_name,
        };
      }),
    };
    dispatch(fetchSettingsListSucces(parsedData));
  } catch (err) {
    dispatch(fetchSettingsListFailure(err));
  }
};
export const CreateLockerColorThunk = (name) => async (dispatch) => {
  dispatch(createResourceRequest());
  try {
    const data = await apiClient().lockersInstance.post("/createlockercolor", {
      locker_color_name: name,
    });
    const parsedData = {
      names: { id: "ID Koloru Skrytki", cell1: "Nazwa Koloru Skrytki" },
      data: {
        id: data.data.id_locker_color,
        cell1: data.data.locker_color_name,
      },
    };

    dispatch(createResouceSucces(parsedData));
    dispatch(
      snackbarIn({
        message: `Kolor ${name} utworzony`,
        state: true,
      })
    );
  } catch (err) {
    dispatch(createResourceFailure(err));
    dispatch(
      snackbarIn({
        message: `Kolor ${name} nie został utworzony`,
        state: true,
      })
    );
  }
};
export const DeleteLockerColorThunk = (Id) => async (dispatch) => {
  dispatch(deleteResourceRequest());
  try {
    const data = await apiClient().lockersInstance.delete(
      "/deletelockercolor",
      {
        params: { id_locker_color: Id },
      }
    );
    dispatch(deleteResourceSucces(Id));
    dispatch(
      snackbarIn({ message: `Kolor skrytki ${Id} usunięty`, state: true })
    );
  } catch (err) {
    dispatch(deleteResourceFailure(err));
    dispatch(
      snackbarIn({
        message: `Kolor skrytki ${Id} nie został usunięty`,
        state: true,
      })
    );
  }
};

export const DeleteLockerKeyThunk = (Id) => async (dispatch) => {
  dispatch(deleteResourceRequest());
  try {
    const data = await apiClient().lockersInstance.delete("/deletelockerkey", {
      params: { id_locker_key: Id },
    });
    dispatch(deleteResourceSucces(Id));
    dispatch(snackbarIn({ message: `Klucz ${Id} usunięty`, state: true }));
  } catch (err) {
    dispatch(deleteResourceFailure(err));
    dispatch(
      snackbarIn({
        message: `Klucz ${Id} nie został usunięty`,
        state: true,
      })
    );
  }
};
export const SegmentProducerListThunk = () => async (dispatch) => {
  dispatch(fetchSettingsListRequest());
  try {
    const data = await apiClient().lockersInstance.get("/listsegmentproducers");
    const parsedData = {
      names: {
        id: "ID Producenta Segmentu",
        cell1: "Nazwa Producenta Segmentu",
      },
      data: data.data.map((element) => {
        return {
          id: element.id_segment_producer,
          cell1: element.segment_producer_name,
        };
      }),
    };
    dispatch(fetchSettingsListSucces(parsedData));
  } catch (err) {
    dispatch(fetchSettingsListFailure(err));
  }
};
export const CreateSegmentProducerThunk = (name) => async (dispatch) => {
  dispatch(createResourceRequest());
  try {
    const data = await apiClient().lockersInstance.post(
      "/createsegmentproducer",
      {
        segment_producer_name: name,
      }
    );
    const parsedData = {
      names: {
        id: "ID Producenta Segmentu",
        cell1: "Nazwa Producenta Segmentu",
      },
      data: {
        id: data.data.id_segment_producer,
        cell1: data.data.segment_producer_name,
      },
    };

    dispatch(createResouceSucces(parsedData));
    dispatch(
      snackbarIn({
        message: `Producent Segmentu ${name} utworzony`,
        state: true,
      })
    );
  } catch (err) {
    dispatch(createResourceFailure(err));
    dispatch(
      snackbarIn({
        message: `Producent Segmentu ${name} nie został utworzony`,
        state: true,
      })
    );
  }
};
export const DeleteSegmentProducerThunk = (Id) => async (dispatch) => {
  dispatch(deleteResourceRequest());
  try {
    const data = await apiClient().lockersInstance.delete(
      "/deletesegmentproducer",
      {
        params: { id_segment_producer: Id },
      }
    );
    dispatch(deleteResourceSucces(Id));
    dispatch(
      snackbarIn({ message: `Producent Segmentu ${Id} usunięty`, state: true })
    );
  } catch (err) {
    dispatch(deleteResourceFailure(err));
    dispatch(
      snackbarIn({
        message: `Producent Segmentu ${Id} nie został usunięty`,
        state: true,
      })
    );
  }
};
export const LockerPositionsListThunk = () => async (dispatch) => {
  dispatch(fetchSettingsListRequest());
  try {
    const data = await apiClient().lockersInstance.get("/listlockerpositions");
    const parsedData = {
      names: {
        id: "ID Pozycji",
        cell1: "Nazwa Pozycji",
      },
      data: data.data.map((element) => {
        return {
          id: element.id_locker_position,
          cell1: element.locker_position_name,
        };
      }),
    };
    dispatch(fetchSettingsListSucces(parsedData));
  } catch (err) {
    dispatch(fetchSettingsListFailure(err));
  }
};
export const CreateLockerPositionThunk = (name) => async (dispatch) => {
  dispatch(createResourceRequest());
  try {
    const data = await apiClient().lockersInstance.post(
      "/createlockerposition",
      {
        locker_position_name: name,
      }
    );
    const parsedData = {
      names: {
        id: "ID Pozycji",
        cell1: "Nazwa Pozycji",
      },
      data: {
        id: data.data.id_locker_position,
        cell1: data.data.locker_position_name,
      },
    };

    dispatch(createResouceSucces(parsedData));
    dispatch(
      snackbarIn({
        message: `Pozycja ${name} utworzona`,
        state: true,
      })
    );
  } catch (err) {
    dispatch(createResourceFailure(err));
    dispatch(
      snackbarIn({
        message: `Pozycja ${name} nie została utworzona`,
        state: true,
      })
    );
  }
};
export const DeleteLockerPositionThunk = (Id) => async (dispatch) => {
  dispatch(deleteResourceRequest());
  try {
    const data = await apiClient().lockersInstance.delete(
      "/deletelockerposition",
      {
        params: { id_locker_position: Id },
      }
    );
    dispatch(deleteResourceSucces(Id));
    dispatch(snackbarIn({ message: `Pozycja ${Id} usunięta`, state: true }));
  } catch (err) {
    dispatch(deleteResourceFailure(err));
    dispatch(
      snackbarIn({
        message: `Pozycja ${Id} nie została usunięta`,
        state: true,
      })
    );
  }
};
export const FloorsListThunk = () => async (dispatch) => {
  dispatch(fetchSettingsListRequest());
  try {
    const data = await apiClient().lockersInstance.get("/listfloors");
    const parsedData = {
      names: {
        id: "ID Piętra",
        cell1: "Nazwa Piętra",
      },
      data: data.data.map((element) => {
        return {
          id: element.id_floor,
          cell1: element.floor_name,
        };
      }),
    };
    dispatch(fetchSettingsListSucces(parsedData));
  } catch (err) {
    dispatch(fetchSettingsListFailure(err));
  }
};
export const CreateFloorThunk = (name) => async (dispatch) => {
  dispatch(createResourceRequest());
  try {
    const data = await apiClient().lockersInstance.post("/createfloor", {
      floor_name: name,
    });
    const parsedData = {
      names: {
        id: "ID Piętra",
        cell1: "Nazwa Piętra",
      },
      data: {
        id: data.data.id_floor,
        cell1: data.data.floor_name,
      },
    };

    dispatch(createResouceSucces(parsedData));
    dispatch(
      snackbarIn({
        message: `Piętro ${name} utworzone`,
        state: true,
      })
    );
  } catch (err) {
    dispatch(createResourceFailure(err));
    dispatch(
      snackbarIn({
        message: `Piętro ${name} nie została utworzona`,
        state: true,
      })
    );
  }
};
export const DeleteFloorThunk = (Id) => async (dispatch) => {
  dispatch(deleteResourceRequest());
  try {
    const data = await apiClient().lockersInstance.delete("/deletefloor", {
      params: { id_floor: Id },
    });
    dispatch(deleteResourceSucces(Id));
    dispatch(snackbarIn({ message: `Piętro ${Id} usunięte`, state: true }));
  } catch (err) {
    dispatch(deleteResourceFailure(err));
    dispatch(
      snackbarIn({
        message: `Piętro${Id} nie zostało usunięte`,
        state: true,
      })
    );
  }
};
export const HelpersThunk = () => async (dispatch) => {
  dispatch(fetchHelpersRequest());
  try {
    const data1 = await apiClient().lockersInstance.get("/listseasons");
    const data2 = await apiClient().lockersInstance.get("/listplaces");
    const data3 = await apiClient().lockersInstance.get("/listlockertypes");
    const data4 = await apiClient().lockersInstance.get("/listfloors");
    const data5 = await apiClient().lockersInstance.get("/listlockerpositions");

    const data6 = await apiClient().lockersInstance.get("/listlockercolors");
    const data7 = await apiClient().lockersInstance.get(
      "/listsegmentproducers"
    );
    const data8 = await apiClient().lockersInstance.get("/findcurrentseason");
    const parsedData = {
      currentSeason: data8.data,
      seasons: data1.data,
      places: data2.data,
      locker_types: data3.data,
      floors: data4.data,
      locker_positions: data5.data,
      locker_colors: data6.data,
      segment_producers: data7.data,
    };

    dispatch(fetchHelpersSucces(parsedData));
  } catch (err) {
    dispatch(fetchHelpersFailure(err));
  }
};
