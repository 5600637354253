import types from "./SettingsTypes";

export const clearState = () => {
  return { type: types.CLEAR_STATE };
};

export const fetchHelpersRequest = (payload) => {
  return { type: types.FETCH_HELPERS_REQUEST, payload: payload };
};
export const fetchHelpersSucces = (payload) => {
  return { type: types.FETCH_HELPERS_SUCCES, payload: payload };
};
export const fetchHelpersFailure = (payload) => {
  return { type: types.FETCH_HELPERS_FAILURE, payload: payload };
};

export const fetchSettingsListRequest = (payload) => {
  return { type: types.FETCH_SETTINGS_REQUEST, payload: payload };
};
export const fetchSettingsListSucces = (payload) => {
  return { type: types.FETCH_SETTINGS_SUCCES, payload: payload };
};
export const fetchSettingsListFailure = (payload) => {
  return { type: types.FETCH_SETTINGS_FAILURE, payload: payload };
};

export const createResourceRequest = (payload) => {
  return { type: types.CREATE_RESOURCE_REQUEST, payload: payload };
};
export const createResouceSucces = (payload) => {
  return { type: types.CREATE_RESOURCE_SUCCES, payload: payload };
};
export const createResourceFailure = (payload) => {
  return { type: types.CREATE_RESOURCE_FAILURE, payload: payload };
};

export const deleteResourceRequest = (payload) => {
  return { type: types.DELETE_RESOURCE_REQUEST, payload: payload };
};
export const deleteResourceSucces = (payload) => {
  return { type: types.DELETE_RESOURCE_SUCCES, payload: payload };
};
export const deleteResourceFailure = (payload) => {
  return { type: types.DELETE_RESOURCE_FAILURE, payload: payload };
};
