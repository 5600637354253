import types from "./UITypes";

export const drawerMenuIn = (payload) => {
  return { type: types.DRAWER_MENU_IN, payload: payload };
};

export const setContentView = (payload) => {
  return { type: types.SET_CONTENT_VIEW, payload: payload };
};

export const snackbarIn = (payload) => {
  return { type: types.SNACKBAR_IN, payload: payload };
};
