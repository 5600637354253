import React from "react";
import { Container } from "@mui/material";

import SortableTable from "../components/SortableTable";

const OccupanciesBox = () => {
  return (
    <Container>
      <SortableTable />
    </Container>
  );
};

export default OccupanciesBox;
