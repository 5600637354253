import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Container,
} from "@mui/material";

const FeesBox = () => {
  return (
    <Container>
      <h1>Kaucje</h1>
    </Container>
  );
};

export default FeesBox;
