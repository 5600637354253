import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Paper,
  Button,
  Container,
  TextField,
  ToggleButton,
  Typography,
  Autocomplete,
  Divider,
  useTheme,
} from "@mui/material";
import OccupanciesCharts from "../components/OccupanciesCharts";
import { useDispatch, useSelector } from "react-redux";
import {
  OccupanciesListThunk,
  OccupancyHistoryListThunk,
} from "../Redux/OccupanciesModule/OccupanciesThunks";

const SortableTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const setSelektor = useSelector((state) => state.SettingsReducer.helpers);
  const places = setSelektor.places.map((element) => {
    return { label: element.place_name, id: element.id_place };
  });
  const seasons = setSelektor.seasons.map((element) => {
    return { label: element.season_name, id: element.id_season };
  });

  const [place, setPlace] = useState({ label: "", id: null });
  const [season, setSeason] = useState({
    label: setSelektor.currentSeason.season_name,
    id: setSelektor.currentSeason.id_season,
  });

  const [viewSwitch, setViewSwitch] = useState("ALL");

  const handlePlaceChange = (val) => {
    setPlace(val);
  };
  const handleSeasonChange = (val) => {
    setSeason(val);
  };

  useEffect(() => {
    season.id == setSelektor.currentSeason.id_season
      ? dispatch(OccupanciesListThunk(season.id))
      : dispatch(OccupancyHistoryListThunk(season.id));
  }, [season]);
  const placeFiltredSelektor = useSelector((state) =>
    state.OccupanciesReducer.data.occupancies.filter(
      (element) => element.place == place.label
    )
  );

  const buttonSwitch = () => {
    switch (viewSwitch) {
      case "ALL": {
        return placeFiltredSelektor;
      }
      case "OCC": {
        const OCCplaceFiltered = placeFiltredSelektor.filter(
          (x) => x.id_order != null
        );
        return OCCplaceFiltered;
      }
      case "VAC": {
        const VACplaceFiltered = placeFiltredSelektor.filter(
          (x) => x.id_order == null
        );
        return VACplaceFiltered;
      }
      default:
        return null;
    }
  };

  return (
    <>
      <OccupanciesCharts data={placeFiltredSelektor} />
      <Paper elevation={3} sx={{ mt: "10px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            backgroundColor: "lightgrey",
            borderTopRightRadius: 4,
            borderTopLeftRadius: 4,
          }}
        >
          <Button
            variant="sortableTableNav"
            sx={{
              backgroundColor: viewSwitch === "ALL" ? "white" : null,
            }}
            onClick={() => {
              setViewSwitch("ALL");
            }}
          >
            wszystkie
          </Button>
          <Button
            variant="sortableTableNav"
            sx={{
              color: theme.sortableTableColors.Occ.main,
              backgroundColor: viewSwitch === "OCC" ? "white" : null,
            }}
            onClick={() => {
              setViewSwitch("OCC");
            }}
          >
            zajęte
          </Button>
          <Button
            variant="sortableTableNav"
            sx={{
              color: theme.sortableTableColors.Vac.main,
              backgroundColor: viewSwitch === "VAC" ? "white" : null,
            }}
            onClick={() => {
              setViewSwitch("VAC");
            }}
          >
            wolne
          </Button>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Autocomplete
            disablePortal
            autoComplete
            sx={{
              width: 250,
              marginTop: "16px",
              marginBottom: "16px",
              marginLeft: "8px",
              marginRight: "8px",
            }}
            id="place"
            disableClearable
            options={places}
            value={place}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, newValue) => handlePlaceChange(newValue)}
            renderInput={(params) => <TextField {...params} label="Miejsce" />}
          />
          <Autocomplete
            disablePortal
            autoComplete
            sx={{
              width: 250,
              marginTop: "16px",
              marginBottom: "16px",
              marginLeft: "8px",
              marginRight: "8px",
            }}
            id="season"
            disableClearable
            options={seasons}
            value={season}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, newValue) => {
              handleSeasonChange(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Sezon" />}
          />
        </Box>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Numer Szafki</TableCell>
              <TableCell>Numer Segmentu</TableCell>
              <TableCell>Numer Klucza</TableCell>
              <TableCell>Zamówienie</TableCell>
              <TableCell>Pietro</TableCell>
              <TableCell>Pozycja</TableCell>

              <TableCell>Typ Szafki</TableCell>
              <TableCell>Kolor Szafki</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {buttonSwitch().map((element) => {
              return (
                <TableRow
                  key={element.id_occupancy || element.id_occupancy_history}
                  sx={{
                    backgroundColor: element.id_order
                      ? theme.sortableTableColors.Occ.background
                      : theme.sortableTableColors.Vac.background,
                  }}
                >
                  <TableCell>{element.locker_sticker_number}</TableCell>
                  <TableCell>{element.id_segment}</TableCell>
                  <TableCell>{element.key_number}</TableCell>
                  <TableCell>{element.id_order}</TableCell>
                  <TableCell>{element.floor}</TableCell>
                  <TableCell>{element.locker_position}</TableCell>
                  <TableCell>{element.locker_type}</TableCell>
                  <TableCell>{element.locker_color}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default SortableTable;
