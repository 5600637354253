import React from "react";
import { Route, Routes } from "react-router-dom";
import { routeNames } from "./constants/routeNames";
import SplashScene from "./scenes/SplashScene";
import HomeScene from "./scenes/HomeScene";

function App() {
  return (
    <div>
      <Routes>
        <Route path={routeNames.splash} element={<SplashScene />} />
        <Route path={routeNames.home} element={<HomeScene />} />
      </Routes>
    </div>
  );
}

export default App;
