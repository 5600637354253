import types from "./AuthTypes";

const initalState = {
  isAuthenticated: false,
  consumerKey: "",
  secretKey: "",
};

const AuthReducer = (state = initalState, action) => {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        consumerKey: action.payload.consumerKey,
        secretKey: action.payload.secretKey,
      };

    case types.LOGOUT:
      return initalState;
    default:
      return state;
  }
};

export default AuthReducer;
