import React, { useEffect, useState } from "react";
import SettingsBox from "./SettingsBox";
import LockersBox from "../scenes/LockersBox";
import OrdersBox from "../scenes/OrdersBox";
import HomeAppBar from "../components/HomeAppBar";
import { Box, Container, Grid, CircularProgress } from "@mui/material";
import FeesBox from "./FeesBox";
import { useDispatch, useSelector } from "react-redux";
import { setInstanceToken } from "../services/apiClient";

import { LockersListThunk } from "../Redux/LockersModule/LockersThunks";

import {
  CreateSeasonThunk,
  DeleteSeasonThunk,
  HelpersThunk,
  SeasonsListThunk,
} from "../Redux/SettingsModule/SettingsThunks";
import OccupanciesBox from "./OccupanciesBox";
import MessageSnackbar from "../components/MessageSnackbar";

const HomeScene = () => {
  const dispatch = useDispatch();
  const UIdrawerSelektor = useSelector((state) => state.UIReducer.drawerMenuIn);
  const UIcontentSelektor = useSelector((state) => state.UIReducer.contentView);
  const contentBoxSwitch = () => {
    switch (UIcontentSelektor) {
      case "LOCKERS":
        return <LockersBox />;
      case "ORDERS":
        return <OrdersBox />;
      case "FEES":
        return <FeesBox />;
      case "OCCUPANCIES":
        return <OccupanciesBox />;
      case "SETTINGS":
        return <SettingsBox />;
      default:
        return null;
    }
  };

  const consumer = useSelector((state) => state.auth.consumerKey);
  const secret = useSelector((state) => state.auth.secretKey);

  useEffect(() => {
    setInstanceToken(consumer, secret);
    dispatch(HelpersThunk());

    dispatch(LockersListThunk());
  }, []);
  const settingsSelector = useSelector((state) => state.SettingsReducer);
  return (
    <Container disableGutters={true} maxWidth={false}>
      <HomeAppBar />
      <MessageSnackbar />
      <Grid sx={{ ml: UIdrawerSelektor ? "250px" : "88px" }}>
        {settingsSelector.loading === true ? (
          <Box
            sx={{
              margin: 24,
              display: "flex",
              alignSelf: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={120} thickness={4} />
          </Box>
        ) : (
          contentBoxSwitch()
        )}
      </Grid>
    </Container>
  );
};

export default HomeScene;
