import types from "./LockersTypes";

const initialState = {
  loading: false,
  error: "",
  segments: [
    {
      SegmentId: "",
      Place: "",
      Floor: "",
      SegmentProducer: "",
    },
  ],
  lockers: [
    {
      LockerId: "",
      SegmentId: "",
      LockerColor: "",
      LockerType: "",
      LockerComment: "",
      LockerStickerNumber: "",
      LockerPosition: "",
      KeyNumber: "",
    },
  ],
};

const LockersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_LOCKERS_LIST_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_LOCKERS_LIST_SUCCES:
      return {
        ...state,
        loading: false,
        error: "",
        lockers: action.payload.lockers,
        segments: action.payload.segments,
      };
    case types.FETCH_LOCKERS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: `Failed to fetch Lockers ${action.payload}`,
      };
    case types.CREATE_SEGMENT_SUCCES:
      return { ...state, segments: [...state.segments, action.payload] };

    case types.EDIT_SEGMENT_SUCCES:
      return {
        ...state,
        segments: state.segments.map((element) =>
          element.id_segment == action.payload.id_segment
            ? {
                id_segment: element.id_segment,
                id_place: action.payload.id_place,
                id_floor: action.payload.id_floor,
                id_segment_producer: element.id_segment_producer,
              }
            : element
        ),
      };
    case types.DELETE_SEGMENT_SUCCES:
      return {
        ...state,
        segments: state.segments.filter(
          (element) => element.id_segment !== action.payload
        ),
      };

    case types.ADD_TO_LIST:
      return {
        ...state,

        lockers: [...state.lockers, action.payload],
      };
    case types.EDIT_ITEM:
      return {
        ...state,
        lockers: state.lockers.map((element) =>
          element.id_locker == action.payload.id_locker
            ? action.payload
            : element
        ),
      };
    case types.REMOVE_FROM_LIST:
      return {
        ...state,
        lockers: state.lockers.filter(
          (element) => element.id_locker !== action.payload
        ),
      };

    default:
      return state;
  }
};

export default LockersListReducer;
