import types from "./OccupanciesTypes";

export const fetchOccupanciesListRequest = () => {
  return { type: types.FETCH_OCCUPANCIES_LIST_REQUEST };
};
export const fetchOccupanciesListSucces = (payload) => {
  return { type: types.FETCH_OCCUPANCIES_LIST_SUCCES, payload: payload };
};
export const fetchOccupanciesListFailure = (payload) => {
  return { type: types.FETCH_OCCUPANCIES_LIST_FAILURE, payload: payload };
};

export const createOccupancyRequest = () => {
  return { type: types.CREATE_OCCUPANCY_REQUEST };
};
export const createOccupancySucces = (payload) => {
  return { type: types.CREATE_OCCUPANCY_SUCCES, payload: payload };
};
export const createOccupancyFailure = (payload) => {
  return { type: types.CREATE_OCCUPANCY_FAILURE, payload: payload };
};

export const deleteOccupancyRequest = () => {
  return { type: types.DELETE_OCCUPANCY_REQUEST };
};
export const deleteOccupancySucces = (payload) => {
  return { type: types.DELETE_OCCUPANCY_SUCCES, payload: payload };
};
export const deleteOccupancyFailure = (payload) => {
  return { type: types.DELETE_OCCUPANCY_FAILURE, payload: payload };
};
