const types = {
  CLEAR_STATE: "CLEAR_STATE",

  FETCH_HELPERS_REQUEST: "FETCH_HELPERS_REQUEST",
  FETCH_HELPERS_SUCCES: "FETCH_HELPERS_SUCCES",
  FETCH_HELPERS_FAILURE: "FETCH_HELPERS_FAILURE",

  FETCH_SETTINGS_REQUEST: "FETCH_SETTINGS_REQUEST",
  FETCH_SETTINGS_SUCCES: "FETCH_SETTINGS_SUCCES",
  FETCH_SETTINGS_FAILURE: "FETCH_SETTINGS_FAILURE",

  CREATE_RESOURCE_REQUEST: "CREATE_RESOURCE_REQUEST",
  CREATE_RESOURCE_SUCCES: "CREATE_RESOURCE_SUCCES",
  CREATE_RESOURCE_FAILURE: "CREATE_RESOURCE_FAILURE",

  DELETE_RESOURCE_REQUEST: "DELETE_RESOURCE_REQUEST",
  DELETE_RESOURCE_SUCCES: "DELETE_RESOURCE_SUCCES",
  DELETE_RESOURCE_FAILURE: "DELETE_RESOURCE_FAILURE",
};

export default types;
